<template>
  <div v-tooltip="tooltip">
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: 'BaseTooltipWrapper',
    props: {
      tooltip: {
        type: String,
        default: ''
      }
    }
  };
</script>
