
  import { computed, defineComponent, ComputedRef, PropType } from 'vue';

  import BaseChanceCard from '@/components/BaseChanceCard.vue';
  import BaseTeamCard from '@/components/BaseTeamCard.vue';
  import BaseOfficeCard from '@/components/BaseOfficeCard.vue';
  import BaseZusCard from '@/components/BaseZusCard.vue';
  import BaseCornerCard from '@/components/BaseCornerCard.vue';
  import BaseIndividualCard from '@/components/BaseIndividualCard.vue';

  import { CARD_TYPES } from '@/constants';
  import { Player } from '@/interfaces/businessgame';

  export default defineComponent({
    name: 'BoardCard',
    props: {
      playerName: {
        type: String,
        default: ''
      },
      card: {
        type: Object,
        default: () => ({})
      },
      players: {
        type: Array as PropType<Player[]>,
        default: () => []
      },
      yourPlayerHighlight: {
        type: Boolean,
        default: false
      },
      yourPlayer: {
        type: Object,
        default: () => ({})
      },
      charge: {
        type: Number,
        default: null
      },
      bonusMoney: {
        type: Number,
        default: 0
      }
    },
    setup(props) {
      const boardCardComponent = computed(() => {
        switch (props.card.type) {
          case CARD_TYPES.TEAM:
            return BaseTeamCard;
          case CARD_TYPES.CHANCE:
            return BaseChanceCard;
          case CARD_TYPES.OFFICE:
            return BaseOfficeCard;
          case CARD_TYPES.ZUS:
            return BaseZusCard;
          case CARD_TYPES.CORNER:
            return BaseCornerCard;
          case CARD_TYPES.INDIVIDUAL:
            return BaseIndividualCard;
          default:
            return BaseTeamCard;
        }
      });

      const owner: ComputedRef<Player | undefined> = computed(() =>
        // @ts-ignore
        props.players.find((player: Player) => player.id === props.card.owner)
      );

      return {
        boardCardComponent,
        owner
      };
    }
  });
