
  import { computed, defineComponent, ComputedRef, PropType } from 'vue';

  import BoardCard from '@/components/BoardCard.vue';
  import { calculateCardCharge } from '@/helpers/players-helpers';
  import { Card, Player } from '@/interfaces/businessgame';

  interface BoardConfigAsObject {
    bottom: Card[];
    left: Card[];
    top: Card[];
    right: Card[];
  }

  export default defineComponent({
    name: 'BoardOutside',
    components: {
      BoardCard
    },
    props: {
      players: {
        type: Array as PropType<Player[]>,
        default: () => []
      },
      bonusMoney: {
        type: Number,
        default: 0
      },
      boardConfig: {
        type: Array as PropType<Card[]>,
        default: () => []
      },
      yourPlayer: {
        type: Object,
        default: () => ({})
      }
    },
    setup(props) {
      const boardConfigAsObject: ComputedRef<BoardConfigAsObject> = computed(() => {
        if (props.boardConfig && props.boardConfig.length) {
          return {
            bottom: props.boardConfig.slice(0, 10),
            left: props.boardConfig.slice(10, 20),
            top: props.boardConfig.slice(20, 30),
            right: props.boardConfig.slice(30, 40)
          };
        } else {
          return {
            bottom: [],
            left: [],
            top: [],
            right: []
          };
        }
      });

      const getCardCharge = (card: Card) => calculateCardCharge(props.boardConfig, card);

      return { boardConfigAsObject, getCardCharge };
    }
  });
