
  import { defineComponent, PropType } from 'vue';

  import { POSSIBLE_ACTIONS_ON_CARDS } from '@/constants';
  import { CardUpgradeConfig } from '@/interfaces/businessgame';

  export default defineComponent({
    name: 'BoardCardDescription',
    props: {
      prices: {
        type: Object,
        default: () => ({})
      },
      actions: {
        type: Object,
        default: () => ({})
      },
      actionAvailable: {
        type: Boolean,
        default: false
      },
      upgradeConfig: {
        type: Object as PropType<CardUpgradeConfig>,
        default: () => ({})
      }
    },
    emits: ['performManualActionOnOwnedCard'],
    setup(props, { emit }) {
      const POSSIBLE_ACTIONS = POSSIBLE_ACTIONS_ON_CARDS;

      const handleSelectAction = (action: string) => {
        emit('performManualActionOnOwnedCard', action);
      };

      const getCategoryStyles = (category: string) => {
        if (category.length > 8) {
          return { fontSize: '0.25em', lineHeight: 1 };
        }
        if (category.length > 16) {
          return { fontSize: '0.2em', lineHeight: 1 };
        }
        if (category.length > 24) {
          return { fontSize: '0.15em', lineHeight: 1 };
        }
        return { fontSize: '0.3em', lineHeight: 1 };
      };

      return {
        POSSIBLE_ACTIONS,
        handleSelectAction,
        getCategoryStyles
      };
    }
  });
