
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'BaseIndividualCard',
    props: {
      color: {
        type: String,
        default: 'black'
      },
      title: {
        type: String,
        default: ''
      },
      price: {
        type: Number,
        default: 0
      },
      team: {
        type: Object,
        default: () => ({
          categoryOne: 0,
          categoryTwo: 0,
          categoryThree: 0,
          categoryFour: 0
        })
      },
      owner: {
        type: Object,
        default: () => ({})
      },
      charge: {
        type: Number,
        default: null
      }
    }
  });
