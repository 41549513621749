
  import { watch, defineComponent, PropType } from 'vue';
  import { buyTeamSound, sellTeamSound } from '@/composables/use-audio';
  import { CardUpgradeConfig } from '@/interfaces/businessgame';
  import { useStore } from 'vuex';

  export default defineComponent({
    name: 'BaseTeamCard',
    props: {
      big: {
        type: Boolean,
        default: false
      },
      color: {
        type: String,
        default: 'black'
      },
      title: {
        type: String,
        default: ''
      },
      price: {
        type: Number,
        default: 0
      },
      owner: {
        type: Object,
        default: () => ({})
      },
      yourPlayer: {
        type: Object,
        default: () => ({})
      },
      team: {
        type: Object,
        default: () => ({
          categoryOne: 0,
          categoryTwo: 0,
          categoryThree: 0,
          categoryFour: 0
        })
      },
      prices: {
        type: Object,
        default: () => ({})
      },
      charge: {
        type: Number,
        default: null
      },
      upgradeConfig: {
        type: Object as PropType<CardUpgradeConfig>,
        default: () => ({})
      }
    },
    setup(props) {
      const store = useStore();
      const cardIsOwnedByLoggedInUser = (): boolean => props.yourPlayer?.id === props.owner?.id;

      watch(
        () => props.team,
        (newVal, oldVal) => {
          if (!cardIsOwnedByLoggedInUser) {
            return;
          }

          const oldCount: number = Object.values(oldVal).reduce((a, b) => a + b, 0);
          const newCount: number = Object.values(newVal).reduce((a, b) => a + b, 0);

          if (store.state.soundOn) {
            if (oldCount > newCount) {
              sellTeamSound.play();
            } else if (oldCount < newCount) {
              buyTeamSound.play();
            }
          }
        }
      );

      return {};
    }
  });
