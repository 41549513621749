import { resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "board-card" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", {
    class: ["board-card__wrapper", {
      'board-card__animation': _ctx.yourPlayerHighlight && !_ctx.owner && _ctx.card.type === 'TEAM',
      'board-card__you': _ctx.yourPlayerHighlight,
      'board-card__owned-by-you': _ctx.owner && _ctx.owner.id === _ctx.yourPlayer.id,
      'board-card__owned-by-others': _ctx.owner && _ctx.owner.id !== _ctx.yourPlayer.id
    }]
  }, [
    _createVNode("div", _hoisted_1, [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.boardCardComponent), _mergeProps(_ctx.card, {
        owner: _ctx.playerName || _ctx.owner,
        charge: _ctx.charge,
        "bonus-money": _ctx.bonusMoney,
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('togglePreviewedCard', _ctx.card)))
      }), null, 16, ["owner", "charge", "bonus-money"]))
    ])
  ], 2))
}