
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'BaseOfficeCard',
    props: {
      big: {
        type: Boolean,
        default: false
      },
      title: {
        type: String,
        default: ''
      },
      price: {
        type: Number,
        default: 0
      },
      image: {
        type: String,
        default: ''
      },
      owner: {
        type: Object,
        default: () => ({})
      },
      charge: {
        type: Number,
        default: null
      },
      baseCharge: {
        type: Number,
        default: null
      }
    }
  });
