
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'BaseCornerCard',
    props: {
      title: {
        type: String,
        default: ''
      },
      bonusMoney: {
        type: Number,
        default: 0
      }
    }
  });
